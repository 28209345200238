.navbar {
  align-items: center;
  background-color: var(--background);
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 calc(var(--padding-xl) * 2.5);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo {
  align-items: center;
  display: flex;
}

.logo img {
  flex: 0 1 auto;
  height: var(--spacing-md);
  margin-right: var(--margin-xs);
  object-fit: contain;
  width: auto;
}

.logo-text {
  color: var(--primaryText);
  font-size: var(--font-size-lg);
  font-weight: 600;
}

.hamburger-logo-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#hamburger-icon {
  padding-right: calc(var(--grid-baseline) * 1px);
  padding-top: var(--padding-xs);
}

.links-button-container {
  display: flex;
  flex-direction: row;
}

.nav-links {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.nav-links a {
  margin: 0 var(--margin-sm);
}

.navbar-waitlist-button {
  padding: var(--padding-xs) var(--padding-sm);
}

.hamburger {
  background: none;
  border: none;
  cursor: pointer;
  display: none;
}

.nav-links.open {
  display: block;
}

@media (max-width: 1320px) {
  .nav-links a {
    margin: 0 var(--margin-xs);
  }

  .navbar {
    padding: 0 calc(var(--padding-xl) * 1.5);
  }
}

@media (max-width: 1024px) {
  .navbar {
    padding: 0 var(--padding-sm);
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 0 var(--padding-xs) var(--padding-xs) var(--padding-xs);
  }

  .logo span {
    display: none;
  }

  .hamburger-logo-container {
    display: flex;
    flex-direction: row;
  }

  .hamburger {
    display: block;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 50vw;
  }

  .nav-links a {
    display: block;
    margin: var(--margin-sm) var(--margin-sm);
  }

  .nav-links.open {
    align-items: flex-start;
    background-color: var(--primaryContainer);
    border-radius: var(--border-radius-sm);
    display: flex;
    left: 0;
    margin: 0 var(--margin-xs);
    position: absolute;
    right: 0;
    top: 100%;
  }
}