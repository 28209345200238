#our-story {
  margin-top: var(--margin-xs);
  padding-bottom: var(--padding-xl);
}

#our-story-section, #team-section {
  width: 100%;
}

#our-story-img {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 var(--padding-sm) var(--padding-sm) var(--padding-sm);
}

#our-story-texts {
  padding-right: var(--padding-sm);
}

#our-story-texts p, #team-section p {
  margin: 0;
  margin-bottom: var(--margin-sm);
  text-align: start;
}

#team-cards {
  align-items: start;
}

@media (max-width: 1024px) {
  #our-story-content {
    flex-direction: column;
  }

  #our-story-img {
    padding: 0;
    padding-bottom: var(--padding-lg);
  }

  #our-story-img img {
    width: 100%;
  }

  #team-cards {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
