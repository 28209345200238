.highlight-pair {
  display: flex;
  height: var(--height-xl);
  justify-content: space-around;
  padding: var(--padding-sm) 0;
  text-align: end;
}

.highlight-text {
  align-items: end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: var(--width-35);
}

.highlight-pair.reverse {
  flex-direction: row-reverse;
  text-align: start;
}

@media (max-width: 1320px) {
  .highlight-pair {
    justify-content: space-between;
  }

  .highlight-text {
    width: var(--width-50);
  }
}

@media (max-width: 1024px) {
  .highlight-pair,
  .highlight-pair.reverse {
    align-items: center;
    flex-direction: column;
    height: auto;
    justify-content: center;
    max-width: var(--width-80);
    text-align: start;
  }

  .highlight-text {
    align-items: start;
  }
}

@media (max-width: 768px) {
  .highlight-pair,
  .highlight-pair.reverse {
    max-width: var(--width-100);
  }

  .highlight-pair img {
    max-width: var(--width-100);
  }

  .highlight-text {
    padding-bottom: var(--padding-xs);
  }
}