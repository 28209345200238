@import '../fonts.css';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

:root {
  --font-family-primary: 'TT Norms', sans-serif;
}

h1,
h2,
h3 {
  font-weight: 600;
}

h1 {
  font-size: var(--font-size-xxl);
  margin-bottom: var(--margin-md);
}

h2 {
  font-size: var(--font-size-xl);
  margin-bottom: var(--margin-xl);
}

h3 {
  font-size: var(--font-size-lg);
  line-height: var(--spacing-md);
  margin: var(--margin-xs);
}

p,
label {
  color: black;
  font-size: var(--font-size-sm);
  font-weight: 300;
  line-height: var(--spacing-sm);
  margin: var(--margin-xs);
}

a {
  color: black;
  font-size: var(--font-size-xs);
  font-weight: 400;
  text-decoration: none;
}

.text-gradient {
  background: linear-gradient(90deg, var(--accentText), var(--primaryText), var(--secondaryText));
  color: transparent;
  -webkit-background-clip: text;
}

.text-highlight {
  color: var(--primaryText);
}

.span-bold {
  font-size: var(--font-size-md);
  font-weight: 600;
}

@media (max-width: 1024px) {
  label {
    font-size: var(--font-size-xs);
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: calc(var(--font-size-lg));
  }

  h3 {
    font-size: var(--font-size-md);
    line-height: var(--spacing-sm);
    margin-bottom: 0;
  }

  p {
    font-size: var(--font-size-xs);
    line-height: var(--spacing-xs);
  }
}

@media (max-width: 430px) {
  h3 {
    font-size: var(--font-size-md);
  }
}
