.ai-usage-section {
  background-color: var(--primary200);
  text-align: center;
}

.feature-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: var(--padding-lg) 0;
}

.feature-container h2 {
  color: var(--primaryContainerText);
}

.feature-row-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.feature-row-2 {
  display: flex;
  position: relative;
  width: 100%;
}

.vertical-line {
  border: 0.5px solid var(--primaryText);
  height: calc(var(--padding-lg) * 2);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0;
}

.horizontal-line {
  border: 1px solid var(--primaryText);
  border-top: none;
  height: var(--padding-lg);
  left: 50%;
  margin-bottom: var(--padding-lg);
  position: relative;
  transform: translate(-50%, 0);
  width: 73%;
}

.cta-box {
  align-items: center;
  background: linear-gradient(var(--primary100), var(--primary100)) padding-box,
              linear-gradient(90deg, var(--accentText), var(--primaryText)) border-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-md);;
  box-sizing: border-box;
  color: var(--primaryText);
  display: flex;
  flex-direction: column;
  line-height: var(--spacing-md);
  margin-bottom: var(--padding-xl);
  overflow: hidden;
  padding: var(--padding-xl) calc(var(--padding-xl) * 3);
  position: relative;
}

.additional-div {
  display: none;
}

@media (max-width: 1320px) {
  .cta-box {
    padding: var(--padding-xl) var(--padding-xl);
  }
}

@media (max-width: 1024px) {
  .feature-row-1 {
    align-items: center;
    flex-direction: column;
  }

  #cta-box-connect-container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .horizontal-line,
  .vertical-line {
    display: none;
  }

  .additional-div {
    border: 0.5px solid var(--primaryText);
    display: block;
    height: var(--spacing-xs);
  }
}

@media (max-width: 768px) {
  .additional-div {
    height: calc(var(--spacing-xs) * 0.5);
  }

  .cta-box {
    padding: var(--padding-sm) var(--padding-xs) var(--padding-xs) var(--padding-xs);
  }
}

@media (max-width: 430px) {
  .cta-box button {
    width: 100%;
  }
}
