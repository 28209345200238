.highlights-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: var(--margin-md) auto var(--margin-xl) auto;
}

@media (max-width: 768px) {
  .highlights-container {
    margin: 0 auto var(--margin-lg) auto;
  }
}
