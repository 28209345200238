.bottom-section {
  padding-bottom: var(--margin-xl);
}

#slogan-div {
  margin-bottom: var(--margin-sm);
}

#slogan-div h2 {
  margin-bottom: var(--margin-md);
}

#info-container {
  padding: var(--padding-md) var(--padding-xxl) var(--padding-md) var(--padding-xxl);
}

#info-container img {
  padding-bottom: var(--padding-xs);
}

#info-container span,
#info-container p {
  color: white;
}

#info-container span {
  padding-bottom: var(--padding-xs);
}

#info-container p {
  font-size: var(--font-size-xs);
  ;
  line-height: var(--spacing-sm);
  margin: 0;
}

#ardorne {
  font-size: 12px;
}

@media (max-width: 1024px) {
  #info-container {
    padding: var(--padding-md) var(--padding-md) var(--padding-md) var(--padding-md);
  }
}

@media (max-width: 768px) {
  #info-container span {
    font-size: var(--font-size-xs);
  }
}