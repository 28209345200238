.button {
  align-items: center;
  background: linear-gradient(90deg, var(--accentText), var(--primaryText));
  border: none;
  border-radius: var(--border-radius-capsule);
  color: white;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-size: calc(var(--font-size-sm));
  justify-content: center;
  margin: var(--margin-xs);
  padding: var(--padding-xs) var(--padding-lg);
  transition: transform 0.1s ease;
}

.button:hover {
  opacity: 0.9;
}

.button:active {
  transform: scale(0.95);
}