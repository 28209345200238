.feature-card {
  align-items: start;
  background: var(--primary100);
  border: 1px solid var(--primaryText);
  border-radius: var(--border-radius-md);
  box-sizing: border-box;
  color: var(--primaryText);
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 25%;
  padding: var(--padding-xs) var(--padding-xs);
  text-align: start;
}

.title-container {
  align-items: center;
  display: flex;
}

.title-container img {
  padding: 0 0 0 var(--padding-xs);
}

@media (max-width: 1320px) {
  .feature-card {
    max-width: 28%;
  }
}

@media (max-width: 1024px) {
  .feature-card {
    min-width: 100%;
    padding: var(--padding-xs) var(--padding-xs);
  }
}