.hero {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: var(--margin-md);
  text-align: center;
}

.hero-text,
.hero-image {
  box-sizing: border-box;
  flex: 1 1 50%;
  padding: var(--padding-xs);
  text-align: start;
}

.hero-text p {
  margin-left: 0;
}

.download-buttons {
  display: flex;
  gap: var(--grid-baseline * 4);
}

.download-buttons a {
  margin-right: 20px;
}

.download-button {
  max-height: 40px;
  width: auto;
  /* margin-right: 20px; */
}

.download-button:last-child {
  margin-right: 0;
}

.hero-image {
  text-align: center;
}

.achievements {
  display: flex;
  gap: var(--spacing-sm);
  justify-content: space-between;
  margin-bottom: var(--margin-sm);
}

.supporters {
  text-align: center;
}

.supporters p {
  font-size: var(--font-size-xs);
}

.supporters div {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-sm);
  justify-content: center;
}

.supporters img {
  flex: 0 1 auto;
  height: var(--spacing-xl);
  object-fit: contain;
  width: auto;
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
  }

  .hero-text,
  .hero-image {
    text-align: center;
  }

  .hero-image img {
    max-width: 90vw;
  }

  .achievements {
    align-items: center;
    flex-direction: column;
  }

  .achievement {
    padding: var(--padding-xs) var(--padding-sm);
    max-width: 100%;
    width: 80%;
  }

  .achievement p {
    line-height: var(--spacing-xs);
  }

  .supporters div {
    gap: 0;
    max-width: 100%;
  }

  .supporters img {
    height: 10vw;
  }
}