#contact-title {
  margin: var(--margin-xs);
}

#contact-title,
#contact-description {
  text-align: start;
}

#submit-button {
  padding: var(--padding-xxs) var(--padding-xl);
}

#form-section-1 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#form-section-1-left {
  flex: 2;
}

#hero-image {
  flex: 1;
  padding: var(--padding-sm) 0 0 var(--padding-md);
}

#message-label {
  margin-top: 0;
}

@media (max-width: 1024px) {
  #contact-title {
    font-size: var(--font-size-xl);
  }

  #hero-image {
    display: none;
  }

  #message-label {
    margin-top: var(--margin-xs);
  }
}
