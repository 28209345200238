:root {
  /* Colors - all */
  /* Primary */
  --primary100: #FFEEE7;
  --primary200: #FFCDB6;
  --primary300: #FFAC85;
  --primary400: #FF8A54;
  --primary500: #FF580A;
  --primary600: #CC4608;
  --primary700: #993506;
  --primary800: #662304;
  --primary900: #331202;

  /* Secondary */
  --secondary100: #FFEFEF;
  --secondary200: #FFCECE;
  --secondary300: #FFADAD;
  --secondary400: #FF8C8C;
  --secondary500: #FF5A5A;
  --secondary600: #CC4848;
  --secondary700: #993636;
  --secondary800: #662424;
  --secondary900: #331212;

  /* Accent */
  --accent100: #FFFCE6;
  --accent200: #FFF7B3;
  --accent300: #FFF280;
  --accent400: #FFEC4D;
  --accent500: #FFE400;
  --accent600: #DBC100;
  --accent700: #B7A000;
  --accent800: #937F00;
  --accent900: #7A6800;

  /* Info */
  --info100: #EDF7FF;
  --info200: #C8E7FF;
  --info300: #A3D7FF;
  --info400: #7EC7FF;
  --info500: #47AFFF;
  --info600: #3389DB;
  --info700: #2366B7;
  --info800: #164893;
  --info900: #0D327A;

  /* Success */
  --success100: #EDFBE6;
  --success200: #C9F2B3;
  --success300: #A5EA80;
  --success400: #80E14D;
  --success500: #4AD400;
  --success600: #3BAA00;
  --success700: #2C7F00;
  --success800: #1E5500;
  --success900: #0F2A00;

  /* Error */
  --error100: #FEE9E9;
  --error200: #FBBEBE;
  --error300: #F89393;
  --error400: #F56868;
  --error500: #F02727;
  --error600: #C01F1F;
  --error700: #901717;
  --error800: #601010;
  --error900: #300808;

  /* Neutral */
  --neutral100: #F5F3F2;
  --neutral200: #E0DCD9;
  --neutral300: #CCC4C0;
  --neutral400: #B8ACA7;
  --neutral500: #998981;
  --neutral600: #857167;
  --neutral700: #5C4135;
  --neutral800: #331202;
  --neutral900: #0A0400;

  /* Background */
  --background: #FDFDFD;

  /* Colors - to use */
  /* Primary */
  --primaryText: var(--primary500);
  --primaryContainer: var(--primary100);
  --primaryContainerText: var(--primary600);

  /* Secondary */
  --secondaryText: var(--secondary500);
  --secondaryContainer: var(--secondary100);
  --secondaryContainerText: var(--secondary600);

  /* Accent */
  --accentText: var(--accent500);
  --accentContainer: var(--accent100);
  --accentContainerText: var(--accent600);

  /* Info */
  --infoText: var(--info500);
  --infoContainer: var(--info100);
  --infoContainerText: var(--info600);

  /* Success */
  --successText: var(--success500);
  --successContainer: var(--success100);
  --successContainerText: var(--success600);

  /* Error */
  --errorText: var(--error500);
  --errorContainer: var(--error100);
  --errorContainerText: var(--error600);

  /* Neutral */
  --neutralText: var(--neutral500);
  --neutralContainer: var(--neutral100);
  --neutralContainerText: var(--neutral600);
}
