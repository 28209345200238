/* General */
* {
  font-variant-ligatures: none;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--background);
  font-family: var(--font-family-primary);
  margin: 0;
  overflow: auto;
}

/* Containers */
.container-vertical {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.container-horizontal {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

/* Container sizing */
.width-medium {
  width: 50%;
}

/* Margin sizing */
.margin-large {
  margin: var(--margin-xl);
}

/* Background color */
.background-orange {
  background: linear-gradient(to right,  var(--primary300), var(--primaryText));
}

/* Border radius */
.border-radius-medium {
  border-radius: var(--border-radius-md);
}

.border-radius-25 {
  border-radius: var(--border-radius-lg);
}

/* Images */
.img-xsmall {
  width: var(--width-xxs);
}

.img-small {
  width: var(--width-sm);
}

.img-medium {
  width: var(--width-md);
}

.img-large {
  width: var(--width-lg);
}

/* Form styles */
form {
  width: 100%;
}

label {
  text-align: start;
}

.label-input-pair {
  display: flex;
  flex-direction: column;
}

.rounded-input-box,
.rounded-selection {
  border: var(--border-thickness) solid;
  border-radius: var(--border-radius-capsule);
  font-size: var(--font-size-xs);
  margin: 0 var(--margin-xs) var(--margin-xs) var(--margin-xs);
  padding: var(--padding-xs);
}

.rounded-selection {
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"/></svg>');
  background-position: calc(100% - var(--spacing-xxs)) center;
  background-repeat: no-repeat;
  background-size: var(--spacing-xs);
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.rounded-input-box::placeholder,
.rounded-textarea::placeholder {
  font-family: var(--font-family-primary);
  font-size: var(--font-size-xs);
}

.rounded-textarea {
  border: var(--border-thickness) solid;
  border-radius: var(--border-radius-sm);
  font-family: 'TT Norms', sans-serif;
  font-size: var(--font-size-xs);
  height: var(--width-sm);
  margin: 0 var(--margin-xs) var(--margin-xs) var(--margin-xs);
  padding: var(--padding-xs);
  resize: none;
}

/* Responsive design */
@media (max-width: 1024px) {
  .width-medium {
    width: 80%;
  }
  
  .img-small {
    width: var(--width-xs);
  }

  form {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .container-vertical {
    margin: 0 auto;
  }

  .width-medium {
    width: 100%;
  }
}
