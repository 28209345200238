.achievement {
  align-items: start;
  background: var(--primaryContainer);
  border-radius: var(--border-radius-md);
  color: var(--primaryContainerText);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  max-width: 25%;
  padding: var(--padding-xs);
  transition: transform 0.1s ease;
}

.achievement:active {
  transform: translateY(var(--padding-xs));
}

@media (max-width: 768px) {
  .achievement {
    max-width: 100%;
    padding: var(--padding-xs) var(--padding-sm);
    width: 80%;
  }
}