.home-content-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: var(--margin-xl) auto;
  max-width: var(--max-width-container);
  padding: 0 calc(var(--padding-xl) * 2.5);
}

@media (max-width: 1320px) {
  .home-content-container {
    padding: 0 calc(var(--padding-xl) * 1.5);
  }
}

@media (max-width: 768px) {
  .home-content-container {
    padding: 0 var(--padding-md);
  }
}