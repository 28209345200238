/* TT Norms Thin */
@font-face {
  font-family: 'TT Norms';
  src: url('../public/TT-Norms-Fonts/TTNorms-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

/* TT Norms Thin Italic */
@font-face {
  font-family: 'TT Norms';
  src: url('../public/TT-Norms-Fonts/TTNorms-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

/* TT Norms ExtraLight */
@font-face {
  font-family: 'TT Norms';
  src: url('../public/TT-Norms-Fonts/TTNorms-ExtraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

/* TT Norms ExtraLight Italic */
@font-face {
  font-family: 'TT Norms';
  src: url('../public/TT-Norms-Fonts/TTNorms-ExtraLightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

/* TT Norms Light */
@font-face {
  font-family: 'TT Norms';
  src: url('../public/TT-Norms-Fonts/TTNorms-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

/* TT Norms Light Italic */
@font-face {
  font-family: 'TT Norms';
  src: url('../public/TT-Norms-Fonts/TTNorms-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

/* TT Norms Regular */
@font-face {
  font-family: 'TT Norms';
  src: url('../public/TT-Norms-Fonts/TTNorms-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

/* TT Norms Italic */
@font-face {
  font-family: 'TT Norms';
  src: url('../public/TT-Norms-Fonts/TTNorms-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

/* TT Norms Medium */
@font-face {
  font-family: 'TT Norms';
  src: url('../public/TT-Norms-Fonts/TTNorms-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

/* TT Norms Medium Italic */
@font-face {
  font-family: 'TT Norms';
  src: url('../public/TT-Norms-Fonts/TTNorms-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

/* TT Norms Bold */
@font-face {
  font-family: 'TT Norms';
  src: url('../public/TT-Norms-Fonts/TTNorms-Bold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

/* TT Norms Bold Italic */
@font-face {
  font-family: 'TT Norms';
  src: url('../public/TT-Norms-Fonts/TTNorms-BoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

/* TT Norms ExtraBold */
@font-face {
  font-family: 'TT Norms';
  src: url('../public/TT-Norms-Fonts/TTNorms-ExtraBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

/* TT Norms ExtraBold Italic */
@font-face {
  font-family: 'TT Norms';
  src: url('../public/TT-Norms-Fonts/TTNorms-ExtraBoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

/* TT Norms Black */
@font-face {
  font-family: 'TT Norms';
  src: url('../public/TT-Norms-Fonts/TTNorms-Black.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

/* TT Norms Black Italic */
@font-face {
  font-family: 'TT Norms';
  src: url('../public/TT-Norms-Fonts/TTNorms-BlackItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

/* TT Norms Heavy */
@font-face {
  font-family: 'TT Norms';
  src: url('../public/TT-Norms-Fonts/TTNorms-Heavy.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

/* TT Norms Heavy Italic */
@font-face {
  font-family: 'TT Norms';
  src: url('../public/TT-Norms-Fonts/TTNorms-HeavyItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}
