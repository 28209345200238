.team-card {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-md);
  width: 30%;
}

.team-card img {
  height: auto;
  padding: var(--padding-sm);
  width: 100%;
}

.team-card img:hover {
  cursor: pointer;
}

@media (max-width: 1024px) {
  .team-card {
    padding: 0;
    width: var(--width-md);
  }

  .team-card img {
    padding: 0;
  }
}

@media (max-width: 430px) {
  .team-card {
    width: 100%;
  }
}
