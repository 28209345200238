/* Defines measurements */

:root {
  /* Grid baseline */
  --grid-baseline: 8.0; /* 8 pixels */

  /* Border thickness */
  --border-thickness: 1px; /* 1px */

  /* Border radius */
  --border-radius-capsule: calc(var(--grid-baseline) * 1500px); /* Capsule buttons - 12000px */
  --border-radius-lg: calc(var(--grid-baseline) * 8px); /* 64px */
  --border-radius-md: calc(var(--grid-baseline) * 4px); /* 32px */
  --border-radius-sm: calc(var(--grid-baseline) * 2px); /* 16px */

  /* Font sizes */
  --font-size-xxl: calc(var(--grid-baseline) * 8px); /* 64px */
  --font-size-xl: calc(var(--grid-baseline) * 5px); /* 40px */
  --font-size-lg: calc(var(--grid-baseline) * 4px); /* 32px */
  --font-size-md: calc(var(--grid-baseline) * 3px); /* 24px */
  --font-size-sm: calc(var(--grid-baseline) * 2.5px); /* 20px */
  --font-size-xs: calc(var(--grid-baseline) * 2px); /* 16px */

  /* Margin */
  --margin-xl: calc(var(--grid-baseline) * 9px); /* 72px */
  --margin-lg: calc(var(--grid-baseline) * 7px); /* 56px */
  --margin-md: calc(var(--grid-baseline) * 5px); /* 40px */
  --margin-sm: calc(var(--grid-baseline) * 4px); /* 32px */
  --margin-xs: calc(var(--spacing-xs) * 0.5); /* 12px */

  /* Padding */
  --padding-xxl: calc(var(--grid-baseline) * 15px); /* 120px */
  --padding-xl: calc(var(--grid-baseline) * 9px); /* 72px */
  --padding-lg: calc(var(--grid-baseline) * 7px); /* 56px */
  --padding-md: calc(var(--grid-baseline) * 5px); /* 40px */
  --padding-sm: calc(var(--grid-baseline) * 4px); /* 32px */
  --padding-xs: calc(var(--spacing-xs) * 0.5); /* 12px */
  --padding-xxs: calc(var(--grid-baseline) * 1px); /* 8px */

  /* Heights */
  --height-xl: calc(var(--grid-baseline) * 55px); /* 440px */
  --height-lg: calc(var(--grid-baseline) * 45px); /* 360px */
  --height-md: calc(var(--grid-baseline) * 35px); /* 280px */
  --height-sm: calc(var(--grid-baseline) * 25px); /* 200px */
  --height-xs: calc(var(--grid-baseline) * 15px); /* 120px */

  /* Widths */
  --width-xl: calc(var(--grid-baseline) * 55px); /* 440px */
  --width-lg: calc(var(--grid-baseline) * 45px); /* 360px */
  --width-md: calc(var(--grid-baseline) * 35px); /* 280px */
  --width-sm: calc(var(--grid-baseline) * 25px); /* 200px */
  --width-xs: calc(var(--grid-baseline) * 15px); /* 120px */
  --width-xxs: calc(var(--grid-baseline) * 10px); /* 80px */
  
  /* Relative units */
  --height-100: 100%; /* Full height */
  --height-50: 50%;
  --height-35: 35%;
  --width-100: 100%; /* Full width */
  --width-80: 80%;
  --width-35: 35%;
  --max-width-container: calc(var(--grid-baseline) * 150px); /* 1200px */

  /* Spacing */
  --spacing-xl: calc(var(--grid-baseline) * 9px); /* 72px */
  --spacing-lg: calc(var(--grid-baseline) * 7px); /* 56px */
  --spacing-md: calc(var(--grid-baseline) * 5px); /* 40px */
  --spacing-sm: calc(var(--grid-baseline) * 4px); /* 32px */
  --spacing-xs: calc(var(--grid-baseline) * 3px); /* 24px */
  --spacing-xxs: calc(var(--grid-baseline) * 2px); /* 16px */
}
